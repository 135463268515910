import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NewPatientPage = () => {
  const data = useStaticQuery(graphql`
    query NewPatientPageQuery {
      site {
        siteMetadata {
          phone
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="New Patient" />
      <section className="py-12 md:py-24 bg-gray-100">
        <h1 className="text-center text-4xl sm:text-5xl font-semibold text-blue-500">
          New Patient
        </h1>
      </section>
      <section className="">
        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div>
              <div className="text-center">
                <h2 className="text-3xl leading-9 font-medium text-gray-900 sm:text-4xl sm:leading-10">
                  Insurance & Fees
                </h2>
                <p className="mt-3 max-w-3xl mx-auto text-lg leading-7 text-gray-500">
                  White Center Chiropractic accepts most health insurances
                  including:
                </p>
              </div>
              <div className="my-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:grid-cols-3">
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Aetna
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Lifewise
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Premera
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Regence
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  First Choice
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Cigna
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  United Health
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Kaiser Permanente
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Medicare
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  L&I
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Auto Insurance (PIP)
                </div>
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 hover:bg-gray-100 text-gray-600 text-sm text-center">
                  Third Party Personal Injury and more
                </div>
              </div>
              <p className="mt-3 max-w-3xl text-center mx-auto text-lg leading-7 text-gray-500">
                Please{" "}
                <a
                  href={`tel:${data.site.siteMetadata.phone}`}
                  className="text-blue-500 hover:underline"
                >
                  call us at (206) 763-7464
                </a>{" "}
                if you have any questions regarding your coverage or if your
                health insurance is not listed.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50">
        <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl leading-9 font-medium tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Don't have any Health Insurance?
          </h2>
          <p className="mt-3 max-w-3xl text-center mx-auto text-lg leading-7 text-gray-500">
            At White Center Chiropractic we offer different payment plans and
            discounts if payment is made at the time of service and billing is
            not required.{" "}
          </p>
          <p className="mt-3 max-w-3xl text-center mx-auto text-lg leading-7 text-gray-500">
            Please{" "}
            <a
              href={`tel:${data.site.siteMetadata.phone}`}
              className="text-blue-500 hover:underline"
            >
              call us at (206) 763-7464
            </a>{" "}
            if you have any questions.
          </p>
        </div>
      </section>
      <section class="bg-white">
        <div class="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 class="text-3xl leading-9 font-medium tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Intake Form
          </h2>
          <div class="mt-8 flex justify-center">
            <p className="text-center lg:text-left mt-3 max-w-3xl mx-auto text-lg leading-7 text-gray-500">
              Save time on your first visit to White Center Chiropractic by
              downloading our intake form.
            </p>
          </div>
          <div class="mt-8 flex justify-center">
            <div class="inline-flex rounded-md shadow">
              <a
                href="/whitecenterchiropractic.pdf"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                target="_blank"
              >
                Download Now
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NewPatientPage
